@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@700&family=Share+Tech+Mono&display=swap");

body {
  margin: 0;
  padding: 0;
  background: #0a0a0a;
  color: #fff;
  cursor: context-menu;
  overflow: hidden;
  font-family: "Share Tech Mono", monospace;
}

#root {
  overflow: hidden;
}

.splash-screen {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  animation: fadeIn 2s ease-in;
  opacity: 1;
}

.splash-screen.fade-out {
  animation: fadeOut 1s ease-in forwards;
}

.logo {
  position: relative;
  margin-bottom: 1rem;
  font-family: "Roboto Condensed", sans-serif;
  line-height: 0.8;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 1;
}

.logo-svg {
  width: 280px;
  height: 160px;
  filter: drop-shadow(4px 3px 1px rgba(255, 255, 255, 0.4));
}

.logo-text {
  font-family: "Roboto Condensed", sans-serif;
  font-size: 6rem;
  font-weight: 700;
  letter-spacing: 1.6px;
  opacity: 0;
  animation: fadeInLogo 2s ease forwards 0.5s;
  text-shadow: 2px 2px 1px rgba(255, 255, 255, 0.4);
}

.tagline {
  text-align: center;
  height: 80px;
  margin-top: 2rem;
  font-size: 2rem;
}

.form-screen {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  background: #0a0a0a;
  animation: slideIn 1s ease-out;
}

.form-screen.fade-out {
  animation: fadeOut 0.5s ease-out forwards;
}

.question {
  min-height: 100px;
  display: flex;
  align-items: flex-end;
  max-width: 700px;
  font-size: 2rem;
  margin-bottom: 2rem;
  text-align: center;
}

.input-container {
  position: relative;
  display: flex;
  width: 100%;
  max-width: 500px;
}

.input-container button {
  height: 100%;
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  opacity: 0.8;
  transition: opacity 0.2s ease;
}

.input-container button:hover {
  opacity: 1;
}

.input-container button svg {
  width: 24px;
  height: 24px;
}

input {
  width: 100%;
  padding: 1rem;
  padding-right: 50px;
  background: transparent;
  border: 2px solid #fff;
  border-radius: 4px;
  color: #fff;
  font-size: 1.2rem;
}

input::placeholder {
  color: rgba(255, 255, 255, 0.5);
}

input.error {
  border-color: #ff4444;
}

input.error::placeholder {
  color: #ff4444;
}

input.error:placeholder-shown + button svg {
  fill: #ff4444;
  transition: fill 0.2s ease;
}

input.error:not(:placeholder-shown) + button svg {
  fill: white;
  transition: fill 0.2s ease;
}

.radio-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 70px;
}

.custom-radio {
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 1.5rem;
  cursor: pointer;
}

.custom-radio input {
  display: none;
}

.radio-button {
  width: 22px;
  height: 22px;
  border: 2px solid #fff;
  border-radius: 50%;
  margin-right: 10px;
  position: relative;
  transition: background 0.3s;
}

.custom-radio input:checked + .radio-button {
  background: #fff;
}

.radio-button::after {
  content: '';
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: #0a0a0a;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  transition: opacity 0.3s;
}

.custom-radio input:checked + .radio-button::after {
  opacity: 1;
}

.particles {
  position: fixed;

  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  pointer-events: none;
}

.particle {
  position: absolute;
  width: 2px;
  height: 2px;
  background: rgba(255, 255, 255, 0.5);
  border-radius: 50%;
  pointer-events: none;
}

.splash-screen,
.form-screen {
  position: relative;
  z-index: 1;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeInLogo {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes float {
  0% {
    transform: translateY(0) translateX(0);
  }
  25% {
    transform: translateY(-15px) translateX(15px);
  }
  50% {
    transform: translateY(0) translateX(30px);
  }
  75% {
    transform: translateY(15px) translateX(15px);
  }
  100% {
    transform: translateY(0) translateX(0);
  }
}

@media (max-width: 1440px) {
  .logo {
    font-size: 14rem;
  }

  .logo-text {
    font-size: 4.5rem;
  }

  .tagline,
  .form-screen {
    font-size: 1.8rem;
  }
}

@media (max-width: 1024px) {
  .logo {
    font-size: 12rem;
  }

  .logo-text {
    font-size: 3.5rem;
  }

  .tagline,
  .form-screen {
    font-size: 1.5rem;
  }

  .input-container {
    max-width: 400px;
  }
}

@media (max-width: 768px) {
  .logo {
    font-size: 8rem;
  }

  .logo-text {
    font-size: 2.5rem;
  }

  .logo-svg {
    width: 200px;
    height: 100px;
  }

  .tagline {
    margin-top: 1rem;
  }

  .tagline,
  .form-screen {
    font-size: 1.2rem;
  }

  .input-container {
    max-width: 320px;
  }
}

@media (max-width: 480px) {
  .logo {
    font-size: 6rem;
  }

  .logo-text {
    font-size: 2rem;
  }

  .tagline,
  .form-screen {
    font-size: 1rem;
    padding: 1rem;
  }

  .input-container {
    max-width: 320px;
  }
}

@media (max-width: 320px) {
  .logo {
    font-size: 4rem;
  }

  .logo-text {
    font-size: 1.5rem;
  }

  .input-container {
    max-width: 300px;
  }
}

@media (hover: none) and (pointer: coarse) {
  body {
    cursor: auto;
  }

  .custom-cursor {
    display: none;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
    transform: scale(1);
  }
  to {
    opacity: 0;
    transform: scale(0.95);
  }
}

@keyframes slideIn {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.success-message {
  max-width: 850px;
  font-size: 2rem;
  text-align: center;
  animation: fadeIn 0.5s ease-in;
}

.pulse-circle {
  width: 30px;
  height: 30px;
  border: 2px solid rgba(255, 255, 255, 0.5);
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  animation: pulse 1.5s infinite;
}

@keyframes pulse {
  0% {
    transform: translate(-50%, -50%) scale(1);
    opacity: 0.5;
  }
  50% {
    transform: translate(-50%, -50%) scale(1.2);
    opacity: 0;
  }
  100% {
    transform: translate(-50%, -50%) scale(1);
    opacity: 0.5;
  }
}
